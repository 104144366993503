<!--Error page starts-->
<section id="error">
	<div class="container-fluid bg-lighten-3">
		<div class="container">
			<div class="text-center mt-4">
				<a [routerLink]="['/']" class="">
					<div class="">
						<img src="https://latinatravel.com.br/images/logoi.png" style="max-width: 80vw;" />
					</div>
				</a>
				<div class="text-bold-700 mt-4">
					Ooops! O conteúdo não foi encontrado.<br>
					<br>
					<small>Favor confirmar o LINK ou endereço digitado.</small>
				</div>
				<br>
				<br>
					<a [routerLink]="['/']" class="mt-4"
						class="button btn-large bg-primary white px-1 py-1 text-bold-700"><i class="soap-icon-beach"></i>
						&nbsp;&nbsp;Página Inicial</a>
			</div>
		</div>
	</div>
</section>
<!--Error page ends-->"

