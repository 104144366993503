import { RouteInfo } from './vertical-menu.metadata';

export const ROUTES: RouteInfo[] = [

  {path: '/hotel', title: 'Hotéis', icon: 'fa fa-bed', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  {path: '/aereo', title: 'Passagens', icon: 'icon-plane', class: '',
    badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  {
    path: '', title: 'Serviços de parceiros', icon: 'ft-award', class: 'has-sub',
     badge: '', badgeClass: 'text-bold-700 px-2', isExternalLink: false,
    submenu: [
      {path: 'https://www.touroperator.com.br/portal/scz04/default.asp?ids=MMDDAY&grpArea=pacotes', title: 'Pacotes', icon: 'fa fa-x soap-icon-beach', class: '',
        badge: '', badgeClass: '', isExternalLink: true, submenu: []},
      {path: 'https://www.touroperator.com.br/portal/scz04/default.asp?ids=MMDDAY&grpArea=carros', title: 'Aluguel de Carros', icon: 'fa fa-x soap-icon-car-1', class: '',
        badge: '', badgeClass: '', isExternalLink: true, submenu: []},
      {path: '/outros/seguros', title: 'Seguros de Viagem', icon: 'fa fa-x fa-umbrella', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: 'https://www.partner.viator.com/pt/67593', title: 'Transfers/Atividades', icon: 'fa fa-bus', class: '',
        badge: '', badgeClass: '', isExternalLink: true, submenu: []},
      {path: 'https://latinatravel.minhaviagem.com.br/', title: 'Ingressos', icon: 'fa fa-ticket-alt', class: '',
        badge: '', badgeClass: '', isExternalLink: true, submenu: []},
//      {path: '/home', title: 'Cruzeiros', icon: 'soap-icon-cruise-3', class: '',
//        badge: '', badgeClass: '', isExternalLink: false, submenu: []},

    ]
  },
  {
    path: '', title: 'Informações', icon: 'ft-info', class: 'has-sub',
     badge: '', badgeClass: 'text-bold-700 px-2', isExternalLink: false,
    submenu: [
      {path: '/info/termos', title: 'Termos de Serviço', icon: 'ft-more-horizontal', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/info/privacidade', title: 'Política de Privacidade', icon: 'ft-more-horizontal', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/info/contatos', title: 'Contatos', icon: 'ft-more-horizontal', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/info/faq', title: 'Perguntas Frequentes', icon: 'ft-more-horizontal', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    ]
  },
 {
    path: '', title: 'Conta', icon: 'ft-user', class: 'has-sub',
     badge: '', badgeClass: 'text-bold-700 px-2', isExternalLink: false,
    submenu: [
      {path: '/conta/entra', title: 'Entrar na Conta', icon: 'ft-log-in', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/conta/cria', title: 'Criar Conta', icon: 'ft-user-plus', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/reserva/lista', title: 'Reservas', icon: 'fa fa-calendar-check', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/cliente/perfil', title: 'Perfil', icon: 'fa ft-user', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {path: '/conta/sai', title: 'Sair', icon: 'ft-power', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    ]
  },
  {path: '/home', title: 'Página inicial', icon: 'fa fa-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
];
