import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AlertService {
    private subAlerta = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router, public toastr: ToastrService) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subAlerta.next();
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subAlerta.next({ type: 'success', text: message });
        if (this.keepAfterNavigationChange) {
            this.toastr.success(message);
        }
    }

    warning(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subAlerta.next({ type: 'warning', text: message });
        this.toastr.warning(message);
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subAlerta.next({ type: 'error', text: message });
        this.toastr.error(message);
    }

    getMessage(): Observable<any> {
        return this.subAlerta.asObservable();
    }
}
