import {Injectable} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {ListReservaRQ} from '../models/listreservarq';
import {AlertService} from './alert.service';

@Injectable()
export class ReservaService {
  public listReservaRQModel: any;
  private headers: any;
  private url = '';

  constructor( private httpClient: HttpClient,
    private alertService: AlertService) {
    this.listReservaRQModel = new ListReservaRQ;
    if (window.location.hostname === 'www.latinatravel.com.br') {
      this.url = 'https://www.latinatravel.com.br/'
    } else {
      this.url = 'https://www.latinatravel.net/';
    }
  }

  reservaReq(RQModel = ''): Observable<any> {
    // console.log('service listReservaRQModel', this.listReservaRQModel)
    if (RQModel) {
      this.listReservaRQModel = RQModel;
    }
    return this.listReservaRQModel;
  }

  reservaAllServ(param = 'list', listReservaRQModel?: ListReservaRQ): Observable<any> {
    const auth = JSON.parse(localStorage.getItem('AUTH_MODEL'));
    if ( !( auth || auth.token || this.listReservaRQModel.origem === '/hotel/final' || this.listReservaRQModel.origem === '/aereo') ) {
      return Observable.of();
    };
    var httpHeaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Accept', 'q=0.8;application/json;q=0.9').set( 'Authorization' , localStorage.getItem('AUTH_MODEL') );
    if (listReservaRQModel) {
      this.listReservaRQModel = listReservaRQModel
    }
    return this.httpClient
      .post(this.url + 'v1/api/book/' + param, JSON.stringify(this.listReservaRQModel), {headers: httpHeaders})
      .pipe(map((body: any) => {
        if (body.codigo === 'OK') {
          this.alertService.success(body.mensagem, false);
        } else {
          if (body.codigo === 'WARNING') {
            this.alertService.warning(body.mensagem, false);
          } else {
            this.alertService.error(body.mensagem, false);
          }
        }
        return body;
      }), catchError((error: any) => {
        this.alertService.error(JSON.stringify(error));
        return error;
      })
      );
  }

}
