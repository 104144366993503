import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export interface ITemplateConfig {
    layout: {
        variant: string;
        menuPosition: string;
        navbar: {
          type: string;
        }
        sidebar: {
            collapsed: boolean;
            size: string;
            backgroundColor: string;
            backgroundImage: boolean;
            backgroundImageURL: string;
        }
    };
}

@Injectable({
  providedIn: 'root'
})


export class ConfigService {
  public templateConf: ITemplateConfig;
  templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();

  constructor() {
    this.setConfigValue();
  }

  // Default configurations for Light layout. Please check *customizer.service.ts* for different colors and bg images options

  setConfigValue() {
    this.templateConf = {
      layout: {
        variant: 'Light',
        menuPosition: 'Side',
        navbar: {
          type: 'Fixed'
        },
        sidebar: {
          collapsed: false,
          size: 'sidebar-md',
          backgroundColor: 'white',
          backgroundImage: false,
          backgroundImageURL: ''
        }
      }
    };
  }

  applyTemplateConfigChange(tempConfig: ITemplateConfig) {
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    this.templateConfSubject.next(this.templateConf);
  }

}
