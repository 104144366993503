<!--Footer Starts-->
<footer class="footer no-print row full-width mx-0 bg-white">
	<div class="navbar col-12 list-inline mx-0">
		<div class="col-12 text-center font-small-1">
			<div class="d-none d-sm-block">
				<a routerLink="/info/contatos" class="btn btn-flat btn-link black"><span class="primary font-small-1">&#9654;</span> Contatos</a>
				<a routerLink="/info/termos" class="btn btn-flat btn-link black"><span class="primary font-small-1">&#9654;</span> Termos de Serviço</a>
				<a routerLink="/info/privacidade" class="btn btn-flat btn-link black"><span class="primary font-small-1">&#9654;</span> Politica de privacidade</a>
				<a routerLink="/info/faq" class="btn btn-flat btn-link black"><span class="primary font-small-1">&#9654;</span> Perguntas frequentes</a>
			</div>
			<div class="full-width py-2">&copy; 2016-2020 Latina Travel Agência de Viagens e Turismo, <span class="text-bold-500 d-inline-block">CNPJ 26.618.034/0001-38</span>
				<span class="direito pull-right pt-2 mx-0 my-0" (click)="onClickTop()">
					<a id="back-to-top"><i class="ft-arrow-up arrow-top bounce"></i></a>
				</span>
			</div>
			<div class="full-width">
				<span class="text-center d-inline-block"> Aceitamos
					<img src="https://latinatravel.com.br/images/assets/img/car/vs.png" alt="" class="car-line">
					<img src="https://latinatravel.com.br/images/assets/img/car/mc.png" alt="" class="car-line">
					<img src="https://latinatravel.com.br/images/assets/img/car/ax.png" alt="" class="car-line">
				</span>
			</div>
		</div>
	</div>
</footer>
<!--Footer Ends-->
