<nav class="navbar navbar-expand-lg navbar-light header-navbar px-0 py-0 bg-white"
     [ngClass]="{'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper px-0 py-0">
    <div class="navbar-header px-0 py-0">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center px-3 py-0 my-0 mx-0 no-print"
           data-toggle="collapse" (click)="toggleSidebar()">
        <i class="fa fa-bars fa-2x secondary px-1 py-1 my-0 px-0"></i>
      </div>
      <a [routerLink]="['/']" class="">
        <div class="ml-4">
          <img src="https://latinatravel.com.br/images/logoi.png" class="d-none d-sm-block logo-nav" style="max-width: 70%" />
        </div>
      </a>
    </div>
    <div class="navbar-container px-0 no-print">
        <ul class="navbar-nav full-width">
          <li class="i18n-dropdown dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language my-1" id="dropdown-flag" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <img class="langimg selected-flag" src="./assets/img/flags/br.png" alt="flag" />
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item py-1 lang" href="javascript:;"><b>Língua:</b> Português do Brasil&nbsp;&nbsp;<img src="./assets/img/flags/br.png" alt="Português" class="langimg"> </a>
              <a class="dropdown-item py-1 curr" href="javascript:;"> <b>Moeda:</b> (R$ / BRL)</a>
            </div>
          </li>
          <li *ngIf="authModel.token" class="dropdown dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link position-relative" id="dropdownBasic1" ngbDropdownToggle>
              <img src="{{authModel.photo}}" alt="{{authModel.firstname}}" class="user-pic">
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdownBasic1">
              <div>
                <p class="text-center text-bold-500 font-small-2 my-0">Olá {{authModel.firstname}}!</p>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item py-1" routerLink="/reserva/lista"> <i class="fa fa-calendar-check primary mr-2"></i> <span class="black">Reservas</span></a>
                <a class="dropdown-item py-1" routerLink="/cliente/perfil"> <i class="fa ft-user primary mr-2"></i> <span class="black">Perfil</span></a>
                <a class="dropdown-item" routerLink="/conta/sai"> <i class="ft-power mr-2 primary"></i> <span class="black">Sair</span></a>
              </div>
            </div>
          </li>
          <li *ngIf="!authModel.token" class="dropdown dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link position-relative" id="dropdownBasic1" ngbDropdownToggle>
              <i class="ft-user font-medium-3 primary"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdownBasic1">
              <div>
                <a class="dropdown-item" routerLink="/conta/entra"> <i class="ft-log-in mr-2 primary"></i> <span class="black">Entrar na conta</span></a>
                <a class="dropdown-item" routerLink="/conta/cria">
                  <i class="ft-user-plus mr-2 primary"></i> <span class="black">Criar conta</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item py-1" routerLink="/reserva/lista"> <i class="fa fa-calendar-check primary mr-2"></i> <span class="black">Reservas</span></a>
              </div>
            </div>
          </li>
      <div class="collapse navbar-collapse full-width" id="navbarSupportedContent">
          <li class="d-none d-lg-block d-xl-block">
            <button type="button" class="btn btn-flat btn-link black my-0" routerLink="/hotel">
              <span class="primary font-small-1">&#9654;</span> Hotéis</button>
            <button type="button" class="btn btn-flat btn-link black my-0" routerLink="/aereo">
              <span class="primary font-small-1">&#9654;</span> Passagens </button>
          </li>
      </div>
        </ul>
    </div>
  </div>
</nav>

