export class ListReservaRQ {
  constructor(
    public token: string = '',
    public origem: string = '',
    public email: string = '',
    public status: string = '',
    public loc: string = '',
    public s_text: string = '',
    public order_by: string = '',
    public per_page: string = '',
    public last_no: string = '',
  ) {
  }
}
