import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, CanActivate } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { User } from '../models/users';
import { Auth0 } from '../models/auth0';
import { AlertService } from './alert.service';

import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class Auth0Service implements CanActivate {
  public authModel: Auth0;
  public socialUser: SocialUser;
  public jwtHelper = new JwtHelperService();
  private url = '';
  private headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'q=0.8;application/json;q=0.9');

  constructor(private router: Router, private httpClient: HttpClient, public socialAuth: SocialAuthService,
    private alertService: AlertService, public toastr: ToastrService) {
    this.authModel = new Auth0;
    if (window.location.hostname === 'www.latinatravel.com.br') {
      this.url = 'https://www.latinatravel.com.br/'
    } else {
      this.url = 'https://www.latinatravel.net/';
    }
  }

  canActivate() {
    if (!this.authModel.token) {
      const auth = JSON.parse(localStorage.getItem('AUTH_MODEL'));
      if (auth) {
        this.authModel = auth;
      }
    }
    if (this.authModel.token !== '') {
      return true;
    }
    this.router.navigate(['/conta/entra']);
    return false;
  }

  getPrevToken(): any {
    return this.authModel;
  }

  getToken(): any {
    if (!this.authModel.token) {
      const auth = JSON.parse(localStorage.getItem('AUTH_MODEL'));
      if (auth) {
        this.authModel = auth;
      }
    }
    if (this.authModel.token && this.authModel.provider === 'LatinaTravel') {
      if (this.isTokenExpired()) {
        if (this.authModel.token) {
          this.toastr.warning('Sessão encerrada!' );
          this.router.navigate(['/home']);
        }
        this.logout();
      }
      return this.authModel;
    }
    // login social
    this.socialAuth.authState.subscribe((socialUser) => {
      this.testSocialUser(socialUser);
    });
    return this.authModel;
  }

  testSocialUser(socialUser?: any) {
    console.log('test socialUser', socialUser);
    console.log('test this.authModel', this.authModel);
    const oldToken = this.authModel.token;
    if (socialUser != null) {
      this.authModel.token = socialUser.authToken;
      if (socialUser.firstName || !socialUser.name) {
        this.authModel.firstname = socialUser.firstName;
      } else {
        this.authModel.firstname = socialUser.name.split(' ')[0];
      }
      this.authModel.id = socialUser.id;
      this.authModel.idtoken = socialUser.idToken;
      this.authModel.photo = socialUser.photoUrl;
      this.authModel.provider = socialUser.provider;
      this.setToken(this.authModel);
    } else {
      this.authModel.token = '';
      this.authModel.firstname = '';
      this.authModel.id = '';
      this.authModel.idtoken = '';
      this.authModel.photo = '';
      this.authModel.provider = '';
      this.setToken(this.authModel);
    }
    if (oldToken && !this.authModel.token) {
      this.toastr.warning('Sessão encerrada!');
      this.router.navigate(['/home']);
    } else if (this.authModel.token && !oldToken) {
      this.toastr.success('Olá, ' + this.authModel.firstname + '!');
      this.router.navigate(['/home']);
    }
  }

  setToken(setModel?: any): void {
    if (setModel) {
      this.authModel = setModel;
      localStorage.setItem('AUTH_MODEL', JSON.stringify(setModel));
    }
  }

  isTokenExpired(): boolean {
    if (!this.authModel.token) { return true };
    if (this.authModel.provider === 'FACEBOOK' || this.authModel.provider === 'GOOGLE') { return false };
    const date = this.jwtHelper.getTokenExpirationDate(this.authModel.token);
    if (date === undefined) { return false };
    return !(date.valueOf() > new Date().valueOf());
  }

  logout(): any {
    this.socialAuth.signOut();
    this.authModel.token = '';
    this.authModel.firstname = '';
    this.authModel.id = '';
    this.authModel.idtoken = '';
    this.authModel.photo = '';
    this.authModel.provider = '';
    this.setToken(this.authModel);
    this.toastr.warning('Sessão encerrada!' );
    this.router.navigate(['/home']);
    return true;
  }

  login(user: User): Observable<any> {
    return this.httpClient
      .post(this.url + 'v1/api/auth/login', JSON.stringify(user), { headers: this.headers })
      .pipe(map((body: any) => {
        if (body.codigo === 'OK') {
          this.setToken(body.conteudo);
          this.alertService.success(body.mensagem, true);
          return body;
        }
        this.alertService.error(body.mensagem);
        return body;
      }), catchError((error: any) => {
        this.alertService.error(JSON.stringify(error.json()));
        return throwError(false);
      })
      );
  }

  create(user: User): Observable<any> {
    return this.httpClient
      .post(this.url + 'v1/api/auth/register', JSON.stringify(user), { headers: this.headers })
      .pipe(map((body: any) => {
        if (body.codigo === 'OK') {
          this.alertService.success(body.mensagem, true);
          return body;
        }
        this.alertService.error(body.mensagem);
        return body;
      }), catchError((error: any) => {
        this.alertService.error(JSON.stringify(error.json()));
        return throwError(false);
      })
      );
  }

  recover(user: User): Observable<any> {
    return this.httpClient
      .post(this.url + 'v1/api/auth/recover', JSON.stringify(user), { headers: this.headers })
      .pipe(map((body: any) => {
        if (body.codigo === 'OK') {
          this.alertService.success(body.mensagem, true);
          return body;
        }
        this.alertService.error(body.mensagem);
        return body;
      }), catchError((error: any) => {
        this.alertService.error(JSON.stringify(error.json()));
        return throwError(false);
      })
      );
  }


}
