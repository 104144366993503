import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Full_ROUTES } from './full-layout.routes';
import { CONTENT_ROUTES } from './content-layout.routes';
import { Log_ROUTES } from './log-layout.routes';

import { FullLayoutComponent } from '../layouts/full/full-layout.component';
import { ContentLayoutComponent } from '../layouts/content/content-layout.component';
import { LogLayoutComponent } from '../layouts/log/log-layout.component';
import { ErrorComponent } from '../error/error.component';

import { Auth0Service } from '../services/auth0.service';

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES },
  { path: '', component: LogLayoutComponent, data: { title: 'logged Views' }, children: Log_ROUTES, canActivate: [Auth0Service] },
  { path: '404', component: ErrorComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
